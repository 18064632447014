* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Papyrus", sans-serif;
  font-optical-sizing: auto;
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Light"; /*Can be any text*/
  src: local("papyrus"), url("./fonts/papyrus.ttf") format("truetype");
}
