nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #691e06;
  padding: 20px 80px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
  border-bottom: 1.5px solid black;
  position: sticky;
  z-index: 10;
}

#home {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

#navbar li {
  list-style: none;
  padding: 0 20px;
  position: relative;
}

#navbar li a {
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 600;
  color: white;
  transition: 0.3s ease-in-out;
}
#navbar li a:hover,
#navbar li a.active {
  color: #fbba72;
}

#mobile {
  display: none;
  z-index: 100000;
}

#mobile i {
  color: white;
  align-items: center;
}

@media screen and (max-width: 843px) {
  #navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 90px;
    right: -300px;
    width: 300px;
    height: 100vh;
    background: #691e06;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 40px 0 0 10px;
    transition: 0.3s ease-in-out;
  }

  #navbar.active {
    right: 0px;
  }

  #navbar li {
    margin-bottom: 25px;
  }

  #navbar li a {
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    color: white;
    transition: 0.3s ease-in-out;
  }

  #mobile {
    display: block;
    margin-top: 10px;
  }

  #mobile i {
    font-size: 24px;
    cursor: pointer;
  }
}
